import colorCodes from "../../../../common/constants/colorCodes";

export const notSustainedData = {
  type: "bar",

  data: {
    labels: [],
    datasets: [
      {
        label: "Total Not Sustained Practices Line",
        type: "line",
        // borderDash: [3, 5],
        yAxisID: "y",
        animation: true,
        data: [],
        datalabels: {
          align: "end",
          anchor: "center",
          color: "#bbb",
          labels: {
            value: {
              textStrokeColor: "#fff",
              textStrokeWidth: 3,
              font: {
                weight: 800,
                family: "BarlowB",
                size: 10
              },
              color: "#333"
            }
          }
        },
        backgroundColor: "#2b5784",
        borderColor: "#2b5784"
      },
      {
        label: "Not-Sustained 30 days Practices",
        type: "bar",
        yAxisID: "y",
        stack: "Stack 0",
        borderRadius: 100,
        borderSkipped: false,
        data: [],
        datalabels: {
          rotation: "270",
          align: "center",
          anchor: "center",
          clamp: true,
          // color: "#bbb",
          labels: {
            value: {
              textStrokeColor: "#444",
              textStrokeWidth: 3,
              font: {
                weight: 800,
                family: "BarlowB",
                size: 10
              },
              color: "#fff"
            }
          }
        },
        backgroundColor: "#FFBA08",
        borderColor: "#FFBA08",
        borderWidth: 1,
        barPercentage: 0.25
      },
      {
        label: "Not-Sustained 60 days Practices",
        type: "bar",
        yAxisID: "y",
        borderRadius: 100,
        stack: "Stack 0",
        borderSkipped: false,
        data: [],
        datalabels: {
          rotation: "270",
          align: "center",
          anchor: "center",
          clamp: true,
          // color: "#bbb",
          labels: {
            value: {
              textStrokeColor: "#444",
              textStrokeWidth: 3,
              font: {
                weight: 800,
                family: "BarlowB",
                size: 10
              },
              color: "#fff"
            }
          }
        },
        backgroundColor: "#E85D04",
        borderColor: "#E85D04",
        borderWidth: 1,
        barPercentage: 0.25
      },
      {
        label: "Not-Sustained 90 days Practices",
        type: "bar",
        borderRadius: 100,
        yAxisID: "y",
        stack: "Stack 0",
        borderSkipped: false,
        data: [],
        datalabels: {
          rotation: "270",
          align: "center",
          anchor: "center",
          clamp: true,
          // color: "#bbb",
          labels: {
            value: {
              textStrokeColor: "#444",
              textStrokeWidth: 3,
              font: {
                weight: 800,
                family: "BarlowB",
                size: 10
              },
              color: "#fff"
            }
          }
        },
        backgroundColor: "#FF0000",
        borderColor: "#FF0000",
        borderWidth: 1,
        barPercentage: 0.25
      },
      {
        label: "Not-Sustained more than 90 days Practices",
        type: "bar",
        borderRadius: 100,
        yAxisID: "y",
        stack: "Stack 0",
        borderSkipped: false,
        data: [],
        datalabels: {
          rotation: "270",
          align: "center",
          anchor: "center",
          clamp: true,
          // color: "#bbb",
          labels: {
            value: {
              textStrokeColor: "#444",
              textStrokeWidth: 3,
              font: {
                weight: 800,
                family: "BarlowB",
                size: 10
              },
              color: "#fff"
            }
          }
        },
        backgroundColor: "#9D0208",
        borderColor: "#9D0208",
        borderWidth: 1,
        barPercentage: 0.25
      }
    ]
  },
  options: {
    responsive: true,
    lineTension: 0,
    interaction: {
      intersect: false
    },
    elements: {
      bar: {
        // hoverBackgroundColor:'yellow'
      }
    },
    scales: {
      y: {
        stacked: true,
        display: true,
        title: {
          display: true,
          text: "Practices",
          padding: { top: 30, left: 0, right: 0, bottom: 0 },
          fontStyle: "bold",
          fontSize: 16,
          fontColor: "#333"
        }
      },

      x: {
        stacked: true
      }
    },
    plugins: {
      title: {
        display: true,
        text: ""
      },
      legend: {
        display: false
      },
      datalabels: {
        formatter: (val) => {
          console.log(val);
          if (val) return `${val.toLocaleString()}`;
          return ``;
        }
        // labels: {
        //   value: {
        //     color: "#004ea8",
        //   },
        // },
      }
    }
  }
};

export default notSustainedData;
