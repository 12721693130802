<template>
    <div class="menu-body">
        <Loader v-if="showLoader" />
        <Breadcrumb />
        <div class="outer-block">
            <div class="button-block">
                <div class="button-dd">
                    <div title="Download All" class="download-view action-btn" @click="downloadPdf">
                        <img alt="download" src="../../../assets/download_icon.svg" />
                    </div>
                </div>
                <!-- <div class="button-dd" v-if="$oidc.userProfile.roles.includes(roles.GLOBALPILLARLEADER)">
                    <div title="Email All Graphs" class="download-view action-btn" @click="emailPdf">
                        <img alt="download" src="../../../assets/email.svg" />
                    </div>
                </div> -->
            </div>
            <div class="plantGap" :id="'group-wise-block-' + index" v-for="(plantData, index) in plantDataBackup"
                :key="index">
                <div class="primary-color title-text center-aligned">
                    {{ new Date().getFullYear() }}
                    {{ plantData.plantName }}{{ plantData.region }}
                </div>

                <canvas :id="'group-wise-chart-' + index" :width="40" :height="10" style="background-color: white"></canvas>

                <div class="sample-list">
                    <div class="prior-years-actual"></div>
                    <span class="sample-span">Total Not-Sustained </span>
                    <div class="ns-30-day"></div>
                    <span class="sample-span">Not-Sustained more than 30 days </span>
                    <div class="ns-60-day"></div>
                    <span class="sample-span">Not-Sustained more than 60 days </span>
                    <div class="ns-90-day"></div>
                    <span class="sample-span">Not-Sustained more than 90 days </span>
                    <div class="more-90-day"></div>
                    <span class="sample-span">Not-Sustained more than 120 days </span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import GlobalChart from "./GlobalChart.vue";
import PlantChart from "./PlantChart.vue";
import Breadcrumb from "../../../components/common/Breadcrumb.vue";
import Loader from "../../../components/common/Loader.vue";
import XLSX from "xlsx";
import Api from "@/common/constants/api";
import ApiCalls from "@/common/api/apiCalls";
import ActionItemFilter from "@/components/Dashboard/ActionItemFilter.vue";
import PDFMake from "@/common/helper/PDFMake.vue";
import Helper from "@/common/helper/";
import Printd from "printd";
import colorCodes from "@/common/constants/colorCodes/index.js";
import html2canvas from "html2canvas";
import SpiderChartReport from "@/common/helper/SpiderChartReport.vue";
import Roles from "@/common/constants/roles";
import Chart from "chart.js/auto";
import ChartJsPluginDataLabels from "chartjs-plugin-datalabels";
import plantChartData from "@/views/Dashboard/data/plant-data";
import notSustainedData from "./data/not-sustained-data";

export default {
    name: "GapEachPlant",
    data() {
        return {
            obj: new Printd(),
            showLoader: false,
            roles: Roles,
            printData: [],
            plantDataBackup: []
        };
    },
    components: {
        Breadcrumb,
        Loader,
        GlobalChart,
        PlantChart,
        ApiCalls,
        Api,
        ActionItemFilter,
        // JsonExcel,
        XLSX
    },
    mixins: [PDFMake, SpiderChartReport],
    beforeCreate() {
        this.$store.dispatch("selectedMenu", {
            main: 3,
            subMenu: 10
        });
        this.$store.dispatch("breadcrumb", {
            title: "Not Sustained Report",
            breadcrumb: [
                { name: "Home / ", primary: true, redirect: "/dashboard" },
                { name: "Reports / ", primary: true, redirect: "/" },
                { name: "Not Sustained Report", primary: false }
            ]
        });
    },
    created() {
        this.showLoader = true;
        this.getPlantGapClosureData();
    },
    methods: {
        downloadPdf() {
            this.download = true;
            this.showLoader = true;
            this.pdfMakeCall("notSustained", this.printData);
            setTimeout(() => {
                this.showLoader = false;
            }, 120 * 1000);
        },
        emailPdf() {
            this.download = true;
            this.showLoader = true;
            this.pdfMakeAndEmail("notSustained", this.printData);
            setTimeout(() => {
                this.showLoader = false;
            }, 120 * 1000);
        },
        getPlantGapClosureData() {
            this.plantDataBackup = [];
            ApiCalls.httpCallDetails(Api.GETNOTSUSTAINED + "/" + new Date().getFullYear(), "get").then((data) => {
                data.data.globalData[0]["plantName"] = "Global";
                data.data.globalData[0]["region"] = "";
                data.data.globalData[0]["label"] = data.data.label
                this.plantDataBackup.push(data.data.globalData[0]);
                data.data.regionData.sort((a, b) => {
                    if (a.regionName < b.regionName) return -1;
                    return a.regionName === b.regionName ? 0 : 1;
                });
                data.data.regionData.forEach((regional) => {
                    regional["plantName"] = regional.regionName;
                    regional["region"] = "";
                    regional["label"] = data.data.label

                });
                this.plantDataBackup = [...this.plantDataBackup, ...data.data.regionData];
                data.data.plantData.forEach((plants) => {
                    plants["region"] = ", " + plants["regionName"];
                    plants["label"] = data.data.label
                });
                data.data.plantData.sort((a, b) => {
                    if (a.regionName < b.regionName) return -1;
                    return a.regionName === b.regionName ? 0 : 1;
                });
                this.plantDataBackup = [...this.plantDataBackup, ...data.data.plantData];

                // this.plantDataBackup.push(
                //     {
                //         type: "Global",
                //         label: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                //         year: 2023,
                //         total: [],
                //         notSustainedPracticeCompleting30Days: [102, 102, 102, 102, 102, 0, 0, 0, 0, 0, 0, 0],
                //         notSustainedPracticeCompleting60Days: [102, 102, 102, 102, 102, 0, 0, 0, 0, 0, 0, 0],
                //         notSustainedPracticeCompleting90Days: [150, 150, 150, 150, 110, 0, 0, 0, 0, 0, 0, 0],
                //         notSustainedPracticeCompletingMoreThan120Days: [30, 30, 30, 50, 50, 0, 0, 0, 0, 0, 0, 0],
                //         plantName: "Global",
                //         region: ""
                //     }
                // )
                setTimeout(() => {
                    this.plantGraph();
                }, 500);
            });
        },
        plantGraph() {
            this.printData = [];
            this.plantDataBackup.forEach((plantData, pos) => {
                this.printData.push("group-wise-block-" + pos);
                console.log(plantData)

                const individualPlantChartData = JSON.parse(JSON.stringify(notSustainedData));

                individualPlantChartData.data.labels = plantData.label;
                individualPlantChartData.data.datasets[0].data = plantData.data.notSustainedPracticeCompleting30Days.map((data, index) => data + plantData.data.notSustainedPracticeCompleting90Days[index] + plantData.data.notSustainedPracticeCompletingMoreThan120Days[index] + plantData.data.notSustainedPracticeCompleting60Days[index]);
                individualPlantChartData.data.datasets[1].data = plantData.data.notSustainedPracticeCompleting30Days //&& plantData.aop.map((i, pos) => (i ? i : null));
                individualPlantChartData.data.datasets[2].data = plantData.data.notSustainedPracticeCompleting60Days //.map((i, pos) => (i ? i - plantData.notSustainedPracticeCompleting30Days[pos] : 0));
                individualPlantChartData.data.datasets[3].data = plantData.data.notSustainedPracticeCompleting90Days //&& plantData.notSustainedPracticeCompleting30Days.map((i, pos) => (i ? i : null));
                individualPlantChartData.data.datasets[4].data = plantData.data.notSustainedPracticeCompletingMoreThan120Days //&& plantData.offtrack.map((i, pos) => (i ? i : null));


                individualPlantChartData.data.datasets[0].datalabels.labels.value.font.size = window.innerWidth < 1500 ? 11 : 14;

                individualPlantChartData.data.datasets[1].datalabels.labels.value.font.size = window.innerWidth < 1500 ? 11 : 16;
                individualPlantChartData.data.datasets[1].barPercentage = 0.35;

                individualPlantChartData.data.datasets[2].datalabels.labels.value.font.size = window.innerWidth < 1500 ? 11 : 16;
                individualPlantChartData.data.datasets[2].barPercentage = 0.35;

                individualPlantChartData.data.datasets[3].datalabels.labels.value.font.size = window.innerWidth < 1500 ? 11 : 16;
                individualPlantChartData.data.datasets[3].barPercentage = 0.35;

                individualPlantChartData.data.datasets[4].datalabels.labels.value.font.size = window.innerWidth < 1500 ? 11 : 16;
                individualPlantChartData.data.datasets[4].barPercentage = 0.35;


                individualPlantChartData.options.plugins.datalabels.formatter = (val, context) => {
                    if (val === 0 || !val) return "";
                    return typeof val === "number" ? val : val[0] - val[1];
                };


                individualPlantChartData.data.datasets[1].borderRadius = plantData.data.notSustainedPracticeCompleting30Days.map((count, pos) => {
                    const borderRadius = plantData.data.notSustainedPracticeCompleting60Days[pos] || plantData.data.notSustainedPracticeCompleting90Days[pos] || plantData.data.notSustainedPracticeCompletingMoreThan120Days[pos] ? 0 : 100
                    return {
                        bottomLeft: 100, bottomRight: 100,
                        topLeft: borderRadius, topRight: borderRadius
                    }
                });

                individualPlantChartData.data.datasets[2].borderRadius = plantData.data.notSustainedPracticeCompleting60Days.map((count, pos) => {
                    const borderRadiusBottom = plantData.data.notSustainedPracticeCompleting30Days[pos] ? 0 : 100
                    const borderRadiusTop = plantData.data.notSustainedPracticeCompleting90Days[pos] || plantData.data.notSustainedPracticeCompletingMoreThan120Days[pos] ? 0 : 100
                    return count ?
                        {
                            bottomLeft: borderRadiusBottom,
                            bottomRight: borderRadiusBottom,
                            topLeft: borderRadiusTop,
                            topRight: borderRadiusTop
                        } : 100;
                });

                individualPlantChartData.data.datasets[3].borderRadius = plantData.data.notSustainedPracticeCompleting90Days.map((count, pos) => {
                    const borderRadiusBottom = plantData.data.notSustainedPracticeCompleting30Days[pos] || plantData.data.notSustainedPracticeCompleting60Days[pos] ? 0 : 100
                    const borderRadiusTop = plantData.data.notSustainedPracticeCompletingMoreThan120Days[pos] ? 0 : 100

                    return count
                        ? {
                            bottomLeft: borderRadiusBottom,
                            bottomRight: borderRadiusBottom,
                            topLeft: borderRadiusTop,
                            topRight: borderRadiusTop
                        }
                        : { bottomLeft: 0, bottomRight: 0, topLeft: 0, topRight: 0 };
                });

                individualPlantChartData.data.datasets[4].borderRadius = plantData.data.notSustainedPracticeCompletingMoreThan120Days.map((count, pos) => {
                    const bottom = plantData.data.notSustainedPracticeCompleting60Days[pos] || plantData.data.notSustainedPracticeCompleting90Days[pos] || plantData.data.notSustainedPracticeCompleting30Days[pos] ? 0 : 100
                    return count ? { bottomLeft: bottom, bottomRight: bottom, topLeft: 100, topRight: 100 } : 100;
                });

                Chart.register(ChartJsPluginDataLabels);
                const ctx = document.getElementById("group-wise-chart-" + pos);
                const obj = new Chart(ctx, individualPlantChartData);
                console.log(individualPlantChartData)
                obj.draw();
            });
            setTimeout(() => {
                this.showLoader = false;
            }, 500);
        },
        colorSelector(statuses) {
            return statuses.map((status) => {
                if (status === "ontrack") return colorCodes.ontrack;
                else if (status === "offtrack") return colorCodes.offtrack;
                else return colorCodes.Prior;
            });
        }
    }
};
</script>
<style scoped>
#chart {
    padding: 0 2rem 2rem 0;
}

.plantGap {
    padding-bottom: 2rem;
}

.outer-block {
    background-color: #fff;
    border-radius: 6px;
    padding: 2rem;
}

.tab-titles {
    padding: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tab-options {
    display: flex;
    margin-top: 2rem;
}

.tab-option-text {
    font: 1.16rem "BarlowR", sans-serif;
    cursor: pointer;
}

.in-action {
    margin-right: 1.688em;
}

.tab-active-option {
    font: 1.18rem "BarlowM", sans-serif;
}

.active-tab-scroll-hr {
    border-top: 3px #274ba7 solid;
    width: 20%;
    margin-left: auto;
    margin-right: auto;
    opacity: 1 !important;
    margin-top: 1rem;
}

.select-dd {
    width: 10%;
}

.button-dd {
    display: inline-flex;
    float: right;
}

.button-block {
    width: 100%;
    height: 3rem;
}

.funnel-con {
    /* border-radius: 2rem;
      border: 1px solid #274ba7;
      margin-left: 1rem;
      font-size: 1.33rem;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem; */
    font-size: 1.33rem;
}

.sample-span {
    margin-right: 1rem;
    font: 1.08rem "BarlowM", sans-serif;
    color: #313131;
}

.sample-list {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
}

.title-text {
    font: 1.33rem "BarlowSb", sans-serif;
    padding: 0 0 2rem 0;
}

.data-table tbody {
    font-size: 0.875em;
}

#reports-gc-data-table th div {
    color: #313131;
}

#reports-gc-data-table tbody tr:nth-child(odd) {
    background-color: #fafafa !important;
}

#reports-gc-data-table tbody tr:nth-child(even):hover {
    background-color: #fff !important;
}

#reports-gc-data-table tr td:first-child {
    padding-left: 1.25em !important;
}

#reports-gc-data-table tr th:first-child {
    padding-left: 1.25em !important;
}

.download-view {
    border-radius: 2rem;
    border: 1px solid #274ba7;
    margin-left: 1rem;
    font-size: 1.33rem;
    margin-right: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.download-view:hover {
    background: #e3e8f7;
}

.action-btn {
    border: 1px #274ba7 solid;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    width: 32px;
    cursor: pointer;
}

.hr {
    position: relative;
    top: -26px;
}

.center-aligned {
    text-align: center;
    padding: 1rem 0 !important;
}

#data-block {
    padding: 0 2rem;
}

#data-block>div.data-table.gn-table.table-responsive {
    height: 40rem;
}
</style>
